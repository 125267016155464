.para{
    letter-spacing: 13px;
   text-transform: uppercase;
   
   position: relative;
   -webkit-box-reflect : below 1px linear-gradient(transparent, #0008);
    line-height: 0.7em;
    outline: none;
   color: #fff;
   font-size: 48px;
   text-align: center;
   animation-duration: 5s ;
   animation-name: title;
   animation-iteration-count: infinite;
   animation-timing-function:linear;
   margin-top: 10px;

 


  
}

@media screen and (max-width: 768px) {
    .para{
        font-size:30px;
        letter-spacing:10px;
    }
  }

  @media screen and (max-width: 480px) {
    .para{
        font-size:28px;
        font-weight: bold;
    }
  }

@keyframes title{
    0%,18%,20%, 50.1%, 60%,65.1%,80%, 90.1%,92% {
        color : #0e3742;
        text-shadow: none;
 
 
    }
    18.1%,20.1%, 30%,50%, 100%{
        color: #fff;
        text-shadow: 0 0 10px #03bcf4,
        0 0 20px #03bcf4,
        0 0 40px #03bcf4,
        0 0 80px #03bcf4,
        0 0 160px #03bcf4;
 
    }
    
}