* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  font-family: "Encode Sans Expanded", sans-serif;
  font-family: "Source Sans Pro", sans-serif;
}

html {
  scroll-behavior: smooth;
}

::-webkit-scrollbar {
  /* background-color: #000;
  width: 0.2rem; */

  display:none !important;
  
} 

::-webkit-scrollbar-thumb {
  border-radius: 1rem;
  background-color: black;
}
